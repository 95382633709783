<script>
import { required, helpers } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import Layout from '../../layouts/main.vue'
import Swal from 'sweetalert2'
import CryptoJS from "crypto-js"
import PageHeader from '@/components/page-header'
import Multiselect from '@vueform/multiselect'
import '@vueform/multiselect/themes/default.css'
// import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  setup() {
    return { v$: useVuelidate() }
  },

  data() {
    return {
      data: {
        faculty: ''
      },
      filiere: {},
      students: {},
      classes: [],
      programmes: [],
      trimestre: [
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '3', label: '3' },
      ],
      studentOptions: [],
      facultiesOptions: [],
      admitions: [],
      filteredAdmitions: [],
      currentTime: '',
      submited: false,
      title: 'Gestion Presences',
      timePicker: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
      },
      items: [
        {
          text: 'Etudiants',
          href: '/new-student'
        },
        {
          text: 'Presences',
          active: true
        }
      ]
    }
  },
  validations: {
    data: {
      course: {
        required: helpers.withMessage('Veuillez selectionnez un cours', required)
      },
      semestre: {
        required: helpers.withMessage("Veuillez choisir le trimestre/semestre", required),
      },
      type_exam: {
        required: helpers.withMessage("Veuillez choirsir le type d'évalution ", required),
      },
    }
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    // flatPickr,
  },
  mounted() {
    this.decryptFiliere()
    this.getClasseCourses()
    this.getAdmitions()
  },

  methods: {
    getAdmitions() {
      const self = this
      this.$store
        .dispatch('getRequest', {
          route: `api/getStudents/${this.data.filiere_id}`,
          data: this.data
        })
        .then(
          function (response) {
            self.admitions = response.data.map(admition => {
              return {
                id: admition.student?.id,
                matricule: admition.student?.matricule,
                nom: admition.student?.nom,
                prenom: admition.student?.prenom,
                checkboxValue: false
              }
            });
            console.log(self.admitions);
          },
          function (error) {
            console.log(error)
          }
        )
    },

    submit() {
      this.v$.data.$touch()
      const students_data = this.admitions.map(student => {
        return {
          student: student.id,
          cours: this.data.course,
          semestre: this.data.semestre,
          type_exam: this.data.type_exam,
          filiere_id: this.data.filiere_id,
          note: student.note,
          apreciation: student.apreciation,
        }
      });

      this.$store.dispatch('postRequest', {
        route: '/api/note',
        data: students_data
      }).then(
        function (response) {
          Swal.fire(response.data.message, 'Les notes ont été enregistré!', 'success')
        },
        function (error) {
          console.log(error)
          Swal.fire({
            title: 'Oops...',
            text: 'cet examen a déjà été enregistrée !',
            icon: 'error',
            confirmButtonClass: 'btn btn-primary w-xs mt-2',
            buttonsStyling: false,
            showCloseButton: true
          })
        }
      )
    },

    decryptFiliere() {
      const encryptedMessage1 = localStorage.getItem("dataF");
      if (!encryptedMessage1) {
        console.error('Aucune donnée trouvée pour "dataF" dans localStorage.');
        return;
      }

      const encryptionKey1 = "Slim#9065";

      try {
        const decrypted1 = CryptoJS.AES.decrypt(encryptedMessage1, encryptionKey1);
        const decryptedMessage1 = decrypted1.toString(CryptoJS.enc.Utf8);

        if (!decryptedMessage1) {
          console.error("Erreur lors du décryptage : le message décrypté est vide ou invalide.");
          return;
        }

        this.filiere = JSON.parse(decryptedMessage1);
        this.data.filiere_id = this.filiere.id;
        this.data.annee_scolaire = localStorage.getItem("selectedYear");

      } catch (error) {
        console.error("Erreur lors du décryptage des données :", error);
      }
    },
    getClasseCourses() {
      this.data.school_year = localStorage.getItem("selectedYear");
      this.data.filiere_id = this.filiere.id;
      this.$store.dispatch("getRequest", {
        route: `/api/getFacultyCourses/${this.data.filiere_id}`,
        data: this.data,
      })
        .then(response => {
          this.programmes = response.data.map(cours => ({
            value: cours.id,
            label: cours.nom_cours,
          }));

        })
        .catch(error => console.log(error));
    },
  }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="col-md-12">
      <div class="card">
        <div class="card-body p-4">
          <div class="row justify-content-between mb-3">
            <div class="col-lg-4">
              <label for="fac" class="form-label">Cours</label>
              <Multiselect class="form-control" id="fac" v-model="data.course" :class="{
                'is-invalid':
                  (v$.data.course.$error && data.course) ||
                  (v$.data.course.$error && submited)
              }" :close-on-select="true" :searchable="true" :show-labels="false" :options="programmes" />
              <div v-for="(item, index) in v$.data.course.$errors" :key="index" class="invalid-feedback">
                <span v-if="
                  (item.$message && data.course) ||
                  (v$.data.course.$error && submited)
                ">{{ item.$message }}</span>
              </div>
            </div>
            <div class="col-lg-4">
              <label for="fac" class="form-label">Trimestre/Semestre</label>
              <Multiselect class="form-control" id="fac" v-model="data.semestre" :class="{
                'is-invalid':
                  (v$.data.semestre.$error && data.semestre) ||
                  (v$.data.semestre.$error && submited)
              }" :close-on-select="true" :searchable="true" :show-labels="false" :options="trimestre" />
              <div v-for="(item, index) in v$.data.semestre.$errors" :key="index" class="invalid-feedback">
                <span v-if="
                  (item.$message && data.semestre) ||
                  (v$.data.semestre.$error && submited)
                ">{{ item.$message }}</span>
              </div>
            </div>
            <div class="col-lg-4">
              <label for="fac" class="form-label">Type d'évalution</label>
              <Multiselect class="form-control" id="fac" v-model="data.type_exam" :class="{
                'is-invalid':
                  (v$.data.type_exam.$error && data.type_exam) ||
                  (v$.data.type_exam.$error && submited)
              }" :close-on-select="true" :searchable="true" :show-labels="false" :options="[
                { value: 'interrogation', label: 'Interrogation' },
                { value: 'devoir', label: 'Devoir' },
                { value: 'composition', label: 'Composition' },
              ]" />
              <div v-for="(item, index) in v$.data.type_exam.$errors" :key="index" class="invalid-feedback">
                <span v-if="
                  (item.$message && data.type_exam) ||
                  (v$.data.type_exam.$error && submited)
                ">{{ item.$message }}</span>
              </div>
            </div>
          </div>

          <div class="table-responsive table-card">
            <table class="table align-middle table-nowrap" id="customerTable">
              <thead>
                <tr>
                  <th scope="col">matricule</th>

                  <th scope="col">Etudiant</th>

                  <th scope="col">Note</th>

                  <th scope="col">Apréciation</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="student in admitions" :key="student.id">
                  <th scope="row">{{ student.matricule }}</th>

                  <td>{{ student.nom }} {{ student.prenom }}</td>

                  <td>
                    <div class="col-lg-8">
                      <input class="form-control-sm" type="number" v-model="student.note" />
                    </div>
                  </td>
                  <td>
                    <select class="form-control-sm" v-model="student.apreciation">
                      <option value="mauvais">Mauvais</option>
                      <option value="passable">Passable</option>
                      <option value="assez bien">Assez bien</option>
                      <option value="bien">Bien</option>
                      <option value="tres bien">Très bien</option>
                      <option value="excellent">Excéllent</option>
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="col-lg-12 mt-3 d-flex justify-content-end">
            <button @click="submit()" type="submit" class="btn btn-success">
              <i class="mdi mdi-arrow-down-bold-box-outline"></i>
              Enregirer
            </button>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
